import Api from '@/services/Index';
const save = async (token,form) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/housing/requests/airport-transfer/' + token,form);
}

const addAccompany = async (id,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/housing/requests/add-accompany/' + id,config);
}
export default {
    save,
    addAccompany
}
